import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import './SurveyComponent.css';
import { uniqueId } from './lib/surveyId';
import { json } from "./json";
import "./SurveyComponent.css";
import { AlreadySubmitted } from "./AlreadySubmitted";
import "./AlreadySubmitted.css";
import ThankYouPage from "./ThankYouPage";
import "./ThankYouPage.css";
import "./json.css";

function SurveyComponent() {
  const survey = new Model(json);


  const [welcomeMessage, setWelcomeMessage] = useState('');
  const [profileImageUrl, setProfileImageUrl] = useState('');
  const [surveyCompleted, setSurveyCompleted] = useState(false);
  const [alreadySubmitted, setAlreadySubmitted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showThankYouPage, setShowThankYouPage] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const name = localStorage.getItem('user');
      const userObj = JSON.parse(name);

      setWelcomeMessage(`Welcome, ${userObj.firstName} ${userObj.lastName}`);
      setProfileImageUrl(userObj.profilePicture[0]);
    };

    fetchData();

    const checkAlreadySubmitted = async () => {
      try {
        const user = JSON.parse(localStorage.getItem("user"));
        const surveyId = uniqueId;
        const url = `${process.env.REACT_APP_BASE_API_URL}/api/getDoc?surveyId=${surveyId}&linkedinProfileId=${user.id}`;

        const response = await axios.get(url, {
          headers: {
            'Content-Type': 'application/json',
            access_token: localStorage.getItem('access_token'),
          },
        });

        if (response.data.message) {
          setAlreadySubmitted(true);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    checkAlreadySubmitted();
  }, []);

  const onComplete = (survey) => {
    setLoading(true);
  
    const documents = [];
    survey.pages.forEach((page) => {
      page.elements.forEach((element) => {
        if (element && element.name && survey.data[element.name]) {
          const document = {
            question: element.title || '',
            answer: survey.data[element.name],
          };
          documents.push(document);
        }
      });
    });
  
    const userObj = JSON.parse(localStorage.getItem('user')) || null;
  
    axios
      .post(`${process.env.REACT_APP_BASE_API_URL}/api/save-doc`, {
        userId: userObj.id,
        uniqueId,
        answer : documents,
      })
      .then((response) => {
        console.log(response.data);
        setSurveyCompleted(true);
        setShowThankYouPage(true);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          setAlreadySubmitted(true);
          setLoading(false);
        } else {
          console.error(error);
          setLoading(false);
        }
      });
  };
  

  if (alreadySubmitted) {
    return <AlreadySubmitted />;
  }

  if (showThankYouPage) {
    return <ThankYouPage />;
  }

  return (
    <>
      {loading ? (
        <div className="loader"></div>
      ) : (
        <>
          {!surveyCompleted && (
            <>
              <img src={profileImageUrl} alt="Profile" className="profile-image" />
              <h1 style={{ textAlign: 'center' }} className="welcome-container">
              
                {welcomeMessage}
              </h1>
              <p style={{ textAlign: 'center' }} className="welcome-below">
                Thank you for participating in our survey.<br></br>Please take a few moments to provide your valuable input by answering the following set of questions.<br></br>Your feedback is valuable to us.<br></br>
              </p>
              <Survey model={survey} onComplete={onComplete} data={[{ message: "Data provided was not an array of documents." }]} />
            </>
          )}
        </>
      )}
    </>
  );
}

export default SurveyComponent;
