import React from 'react';
import "./ThankYouPage.css";

function ThankYouPage() {
  return (
    <div className="container">
     <div className="thumbs-up-icon"></div> 
      <div className="thank-you-page-heading">
        <h1>Thank you for submitting the response!</h1>
        

      </div>
    </div>
  );
}

export default ThankYouPage;
