import React, { useEffect } from 'react';
import './login.css';
import axios from 'axios';
import logo from './img/logo.svg';
import LinkedInLogin from './img/newlogo.png';

function LinkedInLoginButton() {
  const handleLoginClick = async () => {
    try {
      const response = await axios({
        url: 'https://surveyws.dev.afreespace.com/api/Auth2.0_Linkedin',
      });
      const urlToPass = new URL(response.data.authUrl);
      console.log('response', response);
      console.log(urlToPass);
      urlToPass.searchParams.set('redirect_uri', window.location.href + 'callback');
      if (response.data.authUrl) {
        window.location.href = new URL(response.data.authUrl).toString();
        // window.location.href = urlToPass.href
        console.log(urlToPass.href + 'callback');
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="linkedInLogin" onClick={handleLoginClick}>
      <img src={LinkedInLogin} alt="LinkedIn Login" />
    </div>
  );
}

function Login() {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const authCode = urlParams.get('code');
    console.log('authCode:', authCode);
    if (authCode) {
      axios({
        url: '/api/accessToken',
        method: 'post',
        data: {
          authCode,
        },
      })
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);

  return (
    <div className="login-screen">
      <div className="login-card">
        <img src={logo} alt="mylogo"  />

        <h4 className="login-form-subtitle">Please sign in to continue</h4>
        <LinkedInLoginButton />
      </div>
    </div>
  );
}

export default Login;
