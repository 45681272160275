import React from 'react';

export const AlreadySubmitted = () => {
  return (
    <div className="already-submitted-container">
    {/* <FontAwesomeIcon icon="fa-sharp fa-regular fa-circle-check" style={{color: "#f7707b",}} /> */}
      <div className="check-animation"></div>
      <h1 className="already-submitted-heading">Already Submitted!</h1>
      <p className="already-submitted-text">
        Thank you for your previous participation!<br />
        We appreciate your valuable input and contribution to the survey.<br />
        Your responses have already been submitted successfully!
      </p>
    </div>
  );
};
