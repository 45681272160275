/* eslint-disable no-multi-str */
export const json = {
  "showQuestionNumbers": "off",
  "showQuestionName": "off",
  "pages": [
    {
      "name": "page1",
      "title": "Easy Work Benchmarking",
      "elements": [
        {
          "type": "html",
          "showQuestionName": "off",
          "showQuestionTitle": "off",
          "html": "<p>Human action is the result of two simple factors, acting at the same time.<br><br>\
            <p><b>1.Relatability:</b> Humans in general act upon things when they can relate to it (or familiar with it), not otherwise.<br> <br></p>\
            <b>2.Ease of use:</b> Even if they can relate to it, things will be actioned when the effort required is relatively less. Less effort translates to less money, less time, less physical energy, easy learning curve, etc. <br>\
            <p class=\"full-text hidden\"><br>A focus on the convergence of these two factors at every organizational touchpoint can facilitate positive behaviors and desirable actions at the workplace. This is what is referred to as EasyWork. A focus on making work easier (i.e. relatable with high ease of use) within organizations helps attract and retain scarce talent.<br>\
            An EasyWork score is what gives an indication of employees' comfort while navigating a workday, irrespective of whether works from the office or from home. This EasyWork score is critical for organizations to understand where they stand from an 'employees' eyes perspective'. The score about an employer becomes a critical decision-making criterion for potential employees when they consider joining the said organization. How good an organization is will become clear only when there are established industry benchmarks.</span>\
            What you see below is the annual study to establish benchmark EasyWork scores across major cities. You have been invited to participate in this anonymous study as part of the survey sample for 2023. Please contribute with complete honesty and co-create the EasyWork benchmark score for 2023.</span>\
            As a gesture of appreciation, respondents will be sent a copy of three key chapters from the upcoming book (2023) called 'Making Work Easy', co-authored by Oxford professor John Hoffmire, founder of work-tech major Freespace, Raj Krishnamurthy, and workplace strategist Parthajeet Sarma. At the end of the survey, you will be given an option to share your personal details for this exclusive copy.</span></p>\
            <a class=\"read-more-dots\" onclick=\"handleReadMoreClick(this)\">...Show More</a>"
        },
        {
          "type": "radiogroup",
          "showQuestionName": "off",
          "name": "q1",
          "title": "1. Where do you live?",
          "choices": [
            "Mumbai/Thane/Navi Mumbai",
            "Delhi & NCR",
            "Bengaluru",
            "Pune",
            "Chennai",
            "Hyderabad",
            "Kolkata"
          ],
          "showOtherItem": true,
          "otherText": "Other",
          "colCount": 1
        },
        {
          "type": "radiogroup",
          "showQuestionName": "off",
          "showQuestionTitle": "off",
          "name": "q2",
          "title": "2. Gender?",
          "choices": [
            "Male",
            "Female"
          ]
        }
      ]
    },
 




    
    
{
  "name": "page2",
  "title": "Easy Work Benchmarking",

     "elements": [
       
        {
          
          "type": "html",
          "showQuestionName": "off",
          "showQuestionTitle": "off",
          "html": "<h2>Ease of Work when you are working from the office</h2><p>Most knowledge workers get a choice of working from the office as well as from home. People stay attached to an organisation for more than the job itself; they stay for the experience. An employee workplace journey involves a progression of organisational touchpoints. A touchpoint is any direct or indirect contact an employee has with the employing organisation. Employee touchpoints can be encountered within and outside of an office.In a world where employees work virtually as well as from offices, touchpoints can vary from having lunch in the office cafeteria, to having an online interaction over a digital platform, to having a sandwich delivered to a meeting room via an employee app.Throughout the workday, employees go on a journey encountering a variety of touchpoints.<br><br> </p> \ <p class=\"full-text hidden\"> When one travels to the office, one has to traverse organisational touch-points like travel, parking, office cafeteria, and others that are typical of a day in the office. This section covers common touchpoints in an experience journey when one goes to the office. The next section covers common touchpoints one experiences while working remotely.<br><br><br></p>\
                  <a class=\"read-more-dots\" onclick=\"handleReadMoreClick(this)\">...Show More</a><br>"
        },
         {
          
          "type": "html",
          "showQuestionName": "off",
          "showQuestionTitle": "off",
           "html":"<h3> Choice of Work Days</h3><br>\
              <p class=\"full-text hidden\"> Most organisations allow employees a choice to determine on which days of the week they work from office and on which days they work from home.</p>\
              <b>Relatability: </b> At a personal level, how well does your mind relate to the very idea of a choice of days? Is this something that you can relate to conceptually, or something that you find difficult to relate to? Would you rather have a fixed routine and no choice?.<br><br>\
              <b> Ease of Use: </b> Irrespective of whether you can relate well to the concept or not, how easy is it to decide and register your choice of days? For example, is there an organisational app that makes it very easy to choose? Or is the app complex and makes it difficult? Or is there a simple way just calling up your team lead and letting him/her know you will come to office on so and so date? How easy or difficult is it to choose?</p>\
               <a class=\"read-more-dots\" onclick=\"handleReadMoreClick(this)\">...Show More</a>"
                  

                  },    
        {
          "type": "rating",
          "name": "q3",
          "title": "1. Choice of work days? | Relatability",
          "description": "With 10 indicating that you are able to relate very well to the very idea of 'Choice of Work Days' and 1 indicating that you cannot relate to the idea at all, please select a number between 1 and 10, indicating your personal relatability score.",
          "rateMax": 5,
          "showQuestionName": "off",
          "showQuestionTitle": "off"
        }, 
        {
          "type": "rating",
          "name": "q4",
          "title": "2. Choice of work days? | Ease of use",
          "description": "With 10 indicating that it is very easy (for you) to execute a 'Choice of Work Days' and 1 indicating that you find it very difficult to execute a 'Choice of Work Days', please select a number between 1 and 10, indicating your personal 'ease of use' score.",
          "rateMax": 5,
          "showQuestionName": "off",
          "showQuestionTitle": "off"
        },    
        {
          "type": "html",
          "showQuestionName": "off",
          "showQuestionTitle": "off",
          "html": "<br><br><h3>Travelling to office</h3><br>\
                  <p class=\"full-text hidden\"> Office commute, parking can be a make or break touchpoint for many.People choose their place of work conveniently such that travel does not take up too much time and effort.</p>\
                  <b>Relatability:</b> There are those who cannot relate much to the idea of travelling to office, especially after getting used to 'not travelling' during the Covid era. On the other hand, there are those who really like the idea of travel to office, because it is part of a routine. From your personal context, how well do you relate to the very idea of travelling to office?<br><br>\
                  <b>Ease of use:</b> Travelling can be seen as difficult or low on 'ease of use' if it is expensive, if it takes a lot of time, if it takes much physical effort, if finding a parking spot is difficult, if the mode of transport is not fixed, etc. What easy is it for you travel to your office?</p>\
                  <a class=\"read-more-dots\" onclick=\"handleReadMoreClick(this)\">...Show More</a>"
         },
        {
          "type": "rating",
          "name": "q5",
          "title": "1. Travelling to office | Relatability",
          "description": "With 10 indicating that you are able to relate very well to the very idea of 'Choice of Work Days' and 1 indicating that you cannot relate to the idea at all, please select a number between 1 and 10, indicating your personal relatability score.",
          "rateMax": 5,
          
        },
     
    
        {
          "type": "rating",
          "title": "2. Travelling to office | Ease of use",
          "description": "With 10 indicating that you are able to relate very well to the very idea of 'Choice of Work Days' and 1 indicating that you cannot relate to the idea at all, please select a number between 1 and 10, indicating your personal 'ease of use' score.",
          "rateMax": 5,
          "name": "q6",
        },
      


        {
        "type": "html",
        "showQuestionName": "off",
        "showQuestionTitle": "off",
        "html": "<h3>Registering office attendance</h3><br>\
                 <p class=\"full-text hidden\">Different organizations have different ways of tracking attendance. Paper records have largely given way to digital ways of 'signing in' and 'signing out'. While badge swipe in person or virtually remains a popular choice, with the increasing popularity of AI, facial recognition tools are gaining in prominence. At the other end of the spectrum lies small offices that have no formal ways of registering attendance.</p>\
                 <p><b>Relatability:</b> How well do you relate to the way attendance is recorded (or not) within your organization? Are you used to (and relate better) a frictionless method of registering attendance in your previous employer organization and now you cannot relate so well in your current employer organization? <br><br>\
                 <b>Ease of use:</b> How difficult or easy is it to register your attendance? Is it seamless like a one-time badge swiping or is it complex requiring multiple swipes, security checks, physically signing-in, etc? </p>\
                 <a class=\"read-more-dots\" onclick=\"handleReadMoreClick(this)\">...Show More</a>"
        },
         {
          "type": "rating",
          "name": "q7",
          "title": "Registering office attendance | Relatability",
          "description": "With 10 indicating that you are able to relate very well to the very idea of 'Choice of Work Days' and 1 indicating that you cannot relate to the idea at all, please select a number between 1 and 10, indicating your personal relatability score.",
          "rateMax": 5,
          "showQuestionName": "off",
          "showQuestionTitle": "off"
        },
        
        {
          "type": "rating",
          "name": "q8",
          "title": "Registering office attendance | Ease of Use",
          "description": "With 10 indicating that you are able to relate very well to the very idea of 'Choice of Work Days' and 1 indicating that you cannot relate to the idea at all, please select a number between 1 and 10, indicating your personal 'ease of use' score.",
          "rateMax": 5,
          "showQuestionName": "off",
          "showQuestionTitle": "off"
        },

        {
          "type": "html",
          "showQuestionName": "off",
          "showQuestionTitle": "off",
          "html": "<br><br><h3>Finding a seat in the office</h3><br>\
          <p class=\"full-text hidden\"> There was a time when most organisations offered a dedicated seat to every employee. Today, not all do. A seat (or workstation) is today understood as a place to do focus work. When you go to the office, there are other things to do, besides focus work, like collaborating with others. Some require you to book a seat/space/pod/room of your choice beforehand. Others do not have a booking system, and one can occupy any free seat or space. Different models are being tried out.</p>\
          <p><b>Relatability:</b>Not everyone can relate to new experiments with workspace in the office. There are those who prefer a dedicated seating system. At the same time, there are many who prefer to book a seat via an employee app and there are those who does not like booking, but prefer to grab whatever is available. How well can you relate to the method in which seats are allocated to you within your organisation?<br><br>\
          <b>Ease of use:</b> If you have a dedicated seat, you will have no trouble in finding a seat to do focus work every day. However you may have difficulty in finding a seat of choice in the absence of a booking system. Even with a booking system, you may not find it easy to navigate it and do a booking. How easy is it for you to find a seat to do focus work?</p>\
          <a class=\"read-more-dots\" onclick=\"handleReadMoreClick(this)\">...Show More</a>"
        },
          {
            "type": "rating",
            "name": "q9",
            "title": "Finding a Seat | Relatability",
            "description": "With 10 indicating that you are able to relate very well to the very idea of 'Choice of Work Days' and 1 indicating that you cannot relate to the idea at all, please select a number between 1 and 10, indicating your personal relatability score.",
            "rateMax": 5,
            "showQuestionName": "off",
            "showQuestionTitle": "off"
          },
          {
            "type": "rating",
            "name": "q10",
            "title": "Finding a Seat | Ease of Use",
            "description": "With 10 indicating that you are able to relate very well to the very idea of 'Choice of Work Days' and 1 indicating that you cannot relate to the idea at all, please select a number between 1 and 10, indicating your personal 'ease of use' score.",
            "rateMax": 5,
            "showQuestionName": "off",
            "showQuestionTitle": "off"
          },

//choice of work settings
{
  "type": "html",
  "showQuestionName": "off",
  "showQuestionTitle": "off",
  "html": "<br><br><h3>Choice of Work Settings</h3><br>\
            <p class=\"full-text hidden\"> At many workplaces today, a variety of work settings are available to employees to choose from. One can work from a desk, one can work (for some time) from a telephone booth, one can work from a focus pod, or from a sofa in a lounge, and so on. This is because all work does not happen at the desk anymore.</p>\
            <p><b>Relatability:</b> How well do you relate to this idea of a variety of work settings? For example, there are some who like to work from a dedicated desk all day. They do not find the idea of different work settings (based on the type of work they need to do) very appealing.<br><br>\
            <b>Ease of use:</b> Irrespective of whether you like the idea or not, how easy or difficult is it to find the right choice of work setting for you? For example, if you need to concentrate for 1 hour, can you easily find a space that will allow you to concentrate and keep you protected from distractions.</p>\
            <a class=\"read-more-dots\" onclick=\"handleReadMoreClick(this)\">...Show More</a>"
 },

 {
  "type": "rating",
  "name": "q11",
  "title": "1.Choice of work settings | Relatability",           
  "description": "With 10 indicating that it is very easy (for you) to execute a 'Choice of Work Days' and 1 indicating that you find it very difficult to execute a 'Choice of Work Days', please select a number between 1 and 10, indicating your personal relatability score.",
  "rateMax": 5,
  "showQuestionName": "off",
  "showQuestionTitle": "off"
},



{
  "type": "rating",
  "name": "q12",
  "title": "2.Choice of work settings | Ease of use",
  "description": "With 10 indicating that you are able to relate very well to the very idea of 'Choice of Work Days' and 1 indicating that you cannot relate to the idea at all, please select a number between 1 and 10, indicating your personal 'ease of use' score.",
  "rateMax": 5,
  
},


//Signing in and out of different digital platforms

          {
            "type": "html",
            "showQuestionName": "off",
            "showQuestionTitle": "off",
            "html": "<br><br><h3>Signing in and out of different digital platforms</h3><br>\
                   <p class=\"full-text hidden\"> Throughout a work day, employees often end up working on multiple digital platforms, depending on the task at hand. While emails may be handled by one platform, writing a report may require a different platform. Blocking a seat for work may require yet another application, perhaps from a mobile phone. There has been increased dependency on a variety of digital platforms to get work done, post Covid. Often this can be daunting. Efforts are being made by tech companies to integrate all of these and minimise the need to use multiple sign-ins and devices.</p>\
                   <p><b>Relatability:</b> Do you find it overwhelming to work on multiple digital platforms, often simultaneously? Or is this something that you can relate to well and have adopted with ease as the world of work changed post Covid?<br><br>\
                   <b>Ease of use: </b> How easy or difficult is it to work on multiple platforms throughout the day? Does it take too much time? Does it required constant upgrades to your device? Is sign-on easy or complicated?</p>\
                   <a class=\"read-more-dots\" onclick=\"handleReadMoreClick(this)\">...Show More</a>"
          },

         {
            "type": "rating",
            "name": "q13",
            "title": "1.Signing in and out | Relatability",           
            "description": "With 10 indicating that it is very easy (for you) to execute a 'Choice of Work Days' and 1 indicating that you find it very difficult to execute a 'Choice of Work Days', please select a number between 1 and 10, indicating your personal relatability score.",
            "rateMax": 5,
            "showQuestionName": "off",
            "showQuestionTitle": "off"
          },
      
      
      
          {
            "type": "rating",
            "name": "q14",
            "title": "2.Signing in and out | Ease of use",
            "description": "With 10 indicating that you are able to relate very well to the very idea of 'Choice of Work Days' and 1 indicating that you cannot relate to the idea at all, please select a number between 1 and 10, indicating your personal 'ease of use' score.",
            "rateMax": 5,
            
          },
          
  
  //Spaces to meet others
  


          {
            "type": "html",
            "showQuestionName": "off",
            "showQuestionTitle": "off",
            "html": "<br><br><h3>Spaces to meet others</h3><br>\
                     <p class=\"full-text hidden\"> Throughout a typical work day, employees do a variety of work. Part of this includes meeting others, in-person, from within the organisation and from outside as well, for collaborating on projects, for presentations or discussions. Different types of such interactions call for different types of spaces; for example a formal presentation needs a formal meeting room with presentation technology whereas an informal catchup to discuss something related to a project, can be done in a casual setting like a lounge.</p>\
                     <p><b>Relatability:</b> How do you relate to the idea of going from one space to another, as the day progresses, to meet with others. Are you one who is more comfortable being in your own 'space' and ask colleagues to pull up a chair next to yours if they want to chat?<br><br>\
                     <b>Ease of use:</b> How easy is it to find free spaces that are fitted for the type of engagement you wish to have with others, when you need them?</p>\
                     <a class=\"read-more-dots\" onclick=\"handleReadMoreClick(this)\">...Show More</a>"
           },


           {
            "type": "rating",
            "name": "q15",
            "title": "1.Spaces to meet others | Relatability",           
            "description": "With 10 indicating that it is very easy (for you) to execute a 'Choice of Work Days' and 1 indicating that you find it very difficult to execute a 'Choice of Work Days', please select a number between 1 and 10, indicating your personal relatability score.",
            "rateMax": 5,
            "showQuestionName": "off",
            "showQuestionTitle": "off"
          },
      
      
      
          {
            "type": "rating",
            "name": "q16",
            "title": "2.Spaces to meet others | Ease of use",
            "description": "With 10 indicating that you are able to relate very well to the very idea of 'Choice of Work Days' and 1 indicating that you cannot relate to the idea at all, please select a number between 1 and 10, indicating your personal 'ease of use' score.",
            "rateMax": 5,
            
          },

            
//Virtual Interactions


{
  "type": "html",
  "showQuestionName": "off",
  "showQuestionTitle": "off",
  "html": "<br><br><h3>Virtual Interactions</h3><br>\
           <p class=\"full-text hidden\"> Meetings and interactions in the office are today not only 'in-person' but virtual also. Throughout a typical workday, employees often end up having multiple virtual meetings over platforms like Zoom and MS Teams. Although most of these platforms allow one to join in from mobile devices, for such interactions to be effective and easy, it is crucial that the right kind of support technology (like large screens, mics, etc) and spaces are made available. Modern day offices have spaces dedicated for such virtual interactions.</p>\
          <p> <b>Relatability:</b> How well do you relate to the kind of facilities (tech and space) that are provided in your office for effective virtual interactions?<br><br>\
           <b>Ease of use:</b> How easy is it to make use of tech provided for virtual interactions? Is it clunky or super smooth? How easy is it to get access to the space provided for such interactions? </p>\
           <a class=\"read-more-dots\" onclick=\"handleReadMoreClick(this)\">...Show More</a>"
},


 {
  "type": "rating",
  "name": "q17",
  "title": "1.Virtual Interactions | Relatability",           
  "description": "With 10 indicating that it is very easy (for you) to execute a 'Choice of Work Days' and 1 indicating that you find it very difficult to execute a 'Choice of Work Days', please select a number between 1 and 10, indicating your personal relatability score.",
  "rateMax": 5,
  "showQuestionName": "off",
  "showQuestionTitle": "off"
},



{
  "type": "rating",
  "name": "q18",
  "title": "2.Virtual Interactions | Ease of use",
  "description": "With 10 indicating that you are able to relate very well to the very idea of 'Choice of Work Days' and 1 indicating that you cannot relate to the idea at all, please select a number between 1 and 10, indicating your personal 'ease of use' score.",
  "rateMax": 5,
  
},
        


//Spaces for focus/quiet work

{
  "type": "html",
  "showQuestionName": "off",
  "showQuestionTitle": "off",
  "html": "<br><br><h3>Spaces for focus/quiet work</h3><br>\
           <p class=\"full-text hidden\">There are times during a day at the office when you need spaces where you are not interrupted, and where you can focus. A open plan setting does not allow this. Many organisations today provide purpose designed spaces for focus and concentration, like focus pods, a focus room, telephone booths, etc.</p>\
           <p><b>Relatability:</b> How well do you relate to this idea of access to quiet corners in the office? Does this sound and feel alien for you?<br><br>\
           <b>Ease of use:</b> Is it easy to find a quiet space where you can be with yourself and focus, when you need it?</p>\
           <a class=\"read-more-dots\" onclick=\"handleReadMoreClick(this)\">...Show More</a>"
 },


 
 


 {
  "type": "rating",
  "name": "q19",
  "title": "1.Spaces for focus/quiet work | Relatability",           
  "description": "With 10 indicating that it is very easy (for you) to execute a 'Choice of Work Days' and 1 indicating that you find it very difficult to execute a 'Choice of Work Days', please select a number between 1 and 10, indicating your personal relatability score.",
  "rateMax": 5,
  "showQuestionName": "off",
  "showQuestionTitle": "off"
},



{
  "type": "rating",
  "name": "q20",
  "title": "2.Spaces for focus/quiet work| Ease of use",
  "description": "With 10 indicating that you are able to relate very well to the very idea of 'Choice of Work Days' and 1 indicating that you cannot relate to the idea at all, please select a number between 1 and 10, indicating your personal 'ease of use' score.",
  "rateMax": 5,
  
},
        






//Getting Coffee

{
  "type": "html",
  "showQuestionName": "off",
  "showQuestionTitle": "off",
  "html": "<br><br><h3>Getting Coffee</h3><br>\
           <p class=\"full-text hidden\"> Coffee, tea, water, and other light refreshments are key ingredients for a workday fueled with energy and vibrancy. Not only do they help employees focus their minds and improve motor and cognitive functions, but they also add tremendous value in building relationships within the office. 'Catching up over a coffee' is not a mere catchphrase. Coffee machines are often strategically placed to aid conversations and team bonding within offices. Besides enhancing formal and informal interactions between colleagues, the access to coffee and refreshments is also seen as crucial for visitor interactions.</p>\
          <p> <b>Relatability:</b> How well do you relate to the coffee/tea culture within your office? Is it to your liking, or is it something that you cannot relate to? What about the choice of coffee and other refreshments available? Can you relate to that, or do you find it too foreign?<br><br>\
           <b>Ease of use:</b> How easy is it to get a coffee/drink when you need one, for yourself or for your team or for your guests in the office? Does it require too much effort, or is it super easy?</p>\
           <a class=\"read-more-dots\" onclick=\"handleReadMoreClick(this)\">...Show More</a>"
},


 {
  "type": "rating",
  "name": "q21",
  "title": "1.Getting Coffee| Relatability",           
  "description": "With 10 indicating that it is very easy (for you) to execute a 'Choice of Work Days' and 1 indicating that you find it very difficult to execute a 'Choice of Work Days', please select a number between 1 and 10, indicating your personal relatability score.",
  "rateMax": 5,
  "showQuestionName": "off",
  "showQuestionTitle": "off"
},



{
  "type": "rating",
  "name": "q22",
  "title": "2.Getting Coffee| Ease of use",
  "description": "With 10 indicating that you are able to relate very well to the very idea of 'Choice of Work Days' and 1 indicating that you cannot relate to the idea at all, please select a number between 1 and 10, indicating your personal 'ease of use' score.",
  "rateMax": 5,
  
},
        
//Getting Food

{
  "type": "html",
  "showQuestionName": "off",
  "showQuestionTitle": "off",
  "html": "<br><br><h3>Getting Food</h3><br>\
          <p class=\"full-text hidden\"> The availability and choice of food in the office make employees feel more appreciated by their employers. Some organizations offer free food, some offer subsidized food. Many offer a wide choice of food in the office cafeteria, including 'healthy' options. There are some employees who may prefer to have their home-cooked meals in the office, which they carry from home; organizations make it convenient to have it warmed. Having a meal together with colleagues at work can go a long way in developing a strong company culture and team bonding. Of course, it goes without saying that the right kind of food and drinks also help energize employees and boost productivity.</p>\
          <p> <b>Relatability:</b> How well do you relate to the food arrangements within your office? If you get food in your office, do you like the kind of food that is served?<br><br>\
           <b>Ease of use:</b> How easy or difficult is it to get food in the office? Is the cafeteria always very crowded? Is billing smooth/easy? If you get your own food from home, is it easy to get it heated if you want to?</p>\
           <a class=\"read-more-dots\" onclick=\"handleReadMoreClick(this)\">...Show More</a>"
},
 {
  "type": "rating",
  "name": "q23",
  "title": "1.Getting Food| Relatability",           
  "description": "With 10 indicating that it is very easy (for you) to execute a 'Choice of Work Days' and 1 indicating that you find it very difficult to execute a 'Choice of Work Days', please select a number between 1 and 10, indicating your personal relatability score.",
  "rateMax": 5,
  "showQuestionName": "off",
  "showQuestionTitle": "off"
},



{
  "type": "rating",
  "name": "q24",
  "title": "2.Getting Food | Ease of use",
  "description": "With 10 indicating that you are able to relate very well to the very idea of 'Choice of Work Days' and 1 indicating that you cannot relate to the idea at all, please select a number between 1 and 10, indicating your personal 'ease of use' score.",
  "rateMax": 5,
  
},
        



//Wellness at work
 

{
  "type": "html",
  "showQuestionName": "off",
  "showQuestionTitle": "off",
  "html": "<br><br><h3>Wellness at work</h3><br>\
          <p class=\"full-text hidden\">Workplace wellness has become very crucial today, especially when where employees are expected to juggle multiple tasks while meeting stringent deadlines. Covering a wide spectrum from physical to mental wellness, employer organisations today provide a host of facilities that boost wellness at work, like gyms, yoga rooms, meditation rooms, sports facilities, medical room, nap rooms, music room, etc and programs that support the right usage of such facilities.</p>\
          <p><b>Relatability:</b> How happy are you with the wellness programs and facilities that your employer organisation provides? Can you relate to them? Or are they 'out of place' from your personal context?<br><br>\
          <b>Ease of use:</b> Is it easy to avail the facilities that are provided? Is it easy to become a part of wellness programs of your choice? Or is it all complex or confusing? </p>\
          <a class=\"read-more-dots\" onclick=\"handleReadMoreClick(this)\">...Show More</a>"
},
 {
  "type": "rating",
  "name": "q25",
  "title": "1.Wellness at work | Relatability",           
  "description": "With 10 indicating that it is very easy (for you) to execute a 'Choice of Work Days' and 1 indicating that you find it very difficult to execute a 'Choice of Work Days', please select a number between 1 and 10, indicating your personal relatability score.",
  "rateMax": 5,
  "showQuestionName": "off",
  "showQuestionTitle": "off"
},



{
  "type": "rating",
  "name": "q26",
  "title": "2.Wellness at work | Ease of use",
  "description": "With 10 indicating that you are able to relate very well to the very idea of 'Choice of Work Days' and 1 indicating that you cannot relate to the idea at all, please select a number between 1 and 10, indicating your personal 'ease of use' score.",
  "rateMax": 5,
  
},
        


            
      ]
    },

{
    "name": "page3",
    "title": "Easy Work Benchmarking",
        "elements": [
          {
           
            "type": "html",
            "showQuestionName": "off",
            "showQuestionTitle": "off",
            "html": "<h2>Ease of Work when you are working from home</h2><p>Most knowledge workers get a choice of working from home as well. Different organisations have different policies around that. Even when one is working from home, an employee encounters organisational touchpoints throughout the workday. This shorter section covers common organisational touchpoints one experiences while working remotely.</p>",
          
          },   
            {
              "type": "html",
              "showQuestionName": "off",
              "showQuestionTitle": "off",
              "html": "<br><br><h3>Home Office Setup</h3><br>\
                       <p class=\"full-text hidden\"> It is important that the infrastructure setup at home for those 'work from home' days is good enough for delivering work in a frictionless manner. This includes things like computer and peripherals, broadband, space, furniture, heating/cooling, etc.</p>\ <p class=\"full-text hidden\"> <b>Relatability:</b> How well can you relate to your own home office setup? Is it something that you are mentally attuned to? Or is it something that your mind does not agree to? <br>\
                       <p><b>Relatability:</b> How well can you relate to your own home office setup? Is it something that you are mentally attuned to? Or is it something that your mind does not agree to?<br><br>\
                        <b>Ease of use:</b> How easy or difficult is it for you to get work done from your home office set-up? In general, do you feel, it is as easy as getting your work done when you are in office or not as easy?</p>\
                       <a class=\"read-more-dots\" onclick=\"handleReadMoreClick(this)\">...Show More</a>"
                      },
                      
            {
              "type": "rating",
              "name": "q27",
              "title": "1.Home Office Setup| Relatability",           
              "description": "With 10 indicating that it is very easy (for you) to execute a 'Choice of Work Days' and 1 indicating that you find it very difficult to execute a 'Choice of Work Days', please select a number between 1 and 10, indicating your personal relatabilty score.",
              "rateMax": 5,
              "showQuestionName": "off",
              "showQuestionTitle": "off"
            },
            
            
            
            {
              "type": "rating",
              "name": "q28",
              "title": "2.Home Office Setup| Ease of use",
              "description": "With 10 indicating that you are able to relate very well to the very idea of 'Choice of Work Days' and 1 indicating that you cannot relate to the idea at all, please select a number between 1 and 10, indicating your personal 'ease of use' score.",
              "rateMax": 5,
              
            }, 




//Access to people at work



  {
    "type": "html",
    "showQuestionName": "off",
    "showQuestionTitle": "off",
    "html": "<br><br><h3>Access to people at work</h3><br>\
            <p class=\"full-text hidden\">When working from home, the access to colleagues is only virtual. You can no more walk up to someone's desk and solve a problem there and then. You now need to call someone or set up a call in advance if someone is very busy or senior. Some say this makes work a bit inefficient; others disagree. Throughout the day, when you work from home, you may need to reach out to your team, your boss, a colleague from a different team or the IT team to fix an issue with your computer.</p>\
            <p> <b>Relatability:</b> How well can you relate to or comfortable with the idea of reaching out to people virtually throughout the day when you are working remotely?<br><br>\
            <b>Ease of use:</b> Is it easy to get in touch with people when you need them? As easy as when you are in office?</p>\
            <a class=\"read-more-dots\" onclick=\"handleReadMoreClick(this)\">...Show More</a>"
  },
  {
    "type": "rating",
    "name": "q29",
    "title": "1.Access to people at work| Relatability",           
    "description": "With 10 indicating that it is very easy (for you) to execute a 'Choice of Work Days' and 1 indicating that you find it very difficult to execute a 'Choice of Work Days', please select a number between 1 and 10, indicating your personal relatabilty score.",
    "rateMax": 5,
    "showQuestionName": "off",
    "showQuestionTitle": "off"
  },
  
  
  
  {
    "type": "rating",
    "name": "q30",
    "title": "2.Access to people at work| Ease of use",
    "description": "With 10 indicating that you are able to relate very well to the very idea of 'Choice of Work Days' and 1 indicating that you cannot relate to the idea at all, please select a number between 1 and 10, indicating your personal 'ease of use' score.",
    "rateMax": 5,
    
  }, 
            
     
  







  //Access to Relevant Information


  {
    "type": "html",
    "showQuestionName": "off",
    "showQuestionTitle": "off",
    "html": "<br><br><h3>Access to Relevant Information</h3><br>\
            <p class=\"full-text hidden\">Getting relevant information at the right time is crucial to get your work done. When you are in the office, such information can be accessed from the physical filing system or a virtual filing system or by asking your colleagues at work. When you are physically away from your colleagues and working remotely, sometimes it may be a little difficult to secure access to the relevant information when you need it. This may be because the information may be resting in physical files in the office, or perhaps colleagues do not share information as easily as when you are in the office; or for other reasons. It could also be that the access to information is super easy as it is all stored in a cloud server.</p>\
            <p><b>Relatability:</b> How comfortable are you with the idea of looking for relevant information remotely? Can you relate quite well to the way it is within your organisation?<br><br>\
            <b>Ease of use:</b> How easy or difficult is it to get the information when you need it?</p>\
            <a class=\"read-more-dots\" onclick=\"handleReadMoreClick(this)\">...Show More</a>"

  },
 
  {
    "type": "rating",
    "name": "q31",
    "title": "1.Access to Relevant Information | Relatability",           
    "description": "With 10 indicating that it is very easy (for you) to execute a 'Choice of Work Days' and 1 indicating that you find it very difficult to execute a 'Choice of Work Days', please select a number between 1 and 10, indicating your personal relatability score.",
    "rateMax": 5,
    "showQuestionName": "off",
    "showQuestionTitle": "off"
  },
  
  
  
  {
    "type": "rating",
    "name": "q32",
    "title": "2.Access to Relevant Information| Ease of use",
    "description": "With 10 indicating that you are able to relate very well to the very idea of 'Choice of Work Days' and 1 indicating that you cannot relate to the idea at all, please select a number between 1 and 10, indicating your personal 'ease of use' score.",
    "rateMax": 5,
    
  }, 
            
   //Working Collaboratively  
  
   {
    "type": "html",
    "showQuestionName": "off",
    "showQuestionTitle": "off",
    "html": "<br><br><h3>Working Collaboratively </h3><br>\
             <p class=\"full-text hidden\">Working remotely rarely means that one does 'focus work' in isolation only. Remote workdays are peppered with calls and virtual meetings with team members, clients, suppliers, associates, as a lot of collaborative work happens in almost all organisations. However working collaboratively when in office is different from working collaboratively when working remotely. In the office, one can walk up to a team member's desk for a quick resolution to an issue. One can attend scrum meetings and brainstorming meetings in person, infused with coffee. When working remotely, all of this becomes virtual.</p>\
             <b>Relatability:</b> How well do you relate to the idea of virtual collaboration when working remotely?<br><br>\
             <b>Ease of use:</b> Is it easy for you to collaborate virtually when you are working remotely? Do you struggle with the technology, broadband, etc? Or do you find it very easy?</p>\
             <a class=\"read-more-dots\" onclick=\"handleReadMoreClick(this)\">...Show More</a>"
  },
  {
    "type": "rating",
    "name": "q33",
    "title": "1.Working Collaboratively | Relatability",           
    "description": "With 10 indicating that it is very easy (for you) to execute a 'Choice of Work Days' and 1 indicating that you find it very difficult to execute a 'Choice of Work Days', please select a number between 1 and 10, indicating your personal relatability score.",
    "rateMax": 5,
    "showQuestionName": "off",
    "showQuestionTitle": "off"
  },
  {
    "type": "rating",
    "name": "q34",
    "title": "2.Working Collaboratively  | Ease of use",           
    "description": "With 10 indicating that it is very easy (for you) to execute a 'Choice of Work Days' and 1 indicating that you find it very difficult to execute a 'Choice of Work Days', please select a number between 1 and 10, indicating your personal 'ease of use' score.",
    "rateMax": 5,
    "showQuestionName": "off",
    "showQuestionTitle": "off"
  },
  
  



//Food & Refreshments when working from home







  {
    "type": "html",
    "showQuestionName": "off",
    "showQuestionTitle": "off",
    "html": "<br><br><h3>Food & Refreshments when working from home </h3><br>\
            <p class=\"full-text hidden\">One cannot overstate the importance of the right kind of food at the right time and the importance of hydration in the functioning of the brain and the body in a way that productivity and efficiency remains high. Getting water, coffee and food when working from home is a different ballgame than when working from office. Some find it very easy to walk across to the kitchen and get what one wants, as when when he/she wants. There are others who do not like this or are not used to this.</p>\
            <p><b>Relatability:</b> How well do you relate to the idea of having lunch in isolation at home in the middle of a workday? How comfortable are you handling your own food from your own kitchen or via food delivery apps on days when you do not have access to your office cafeteria? How well do you relate to the type of food choices you get this way? Do you get the kind of food and refreshments you are used to? Can you relate well with the choices? <br><br>\   <b>Ease of use:</b> How easy is it for you to get fix your own lunch or coffee when you need them? Is it too cumbersome and takes too much time or too much money? </p>\
            <a class=\"read-more-dots\" onclick=\"handleReadMoreClick(this)\">...Show More</a>"
  },
  {
    "type": "rating",
    "name": "q35",
    "title": "1.Food & Refreshments when working from home| Relatability",           
    "description": "With 10 indicating that it is very easy (for you) to execute a 'Choice of Work Days' and 1 indicating that you find it very difficult to execute a 'Choice of Work Days', please select a number between 1 and 10, indicating your personal relatability score.",
    "rateMax": 5,
    "showQuestionName": "off",
    "showQuestionTitle": "off"
  },
  {
    "type": "rating",
    "name": "q36",
    "title": "2.Food & Refreshments when working from home | Ease of use",           
    "description": "With 10 indicating that it is very easy (for you) to execute a 'Choice of Work Days' and 1 indicating that you find it very difficult to execute a 'Choice of Work Days', please select a number between 1 and 10, indicating your personal 'ease of use' score.",
    "rateMax": 5,
    "showQuestionName": "off",
    "showQuestionTitle": "off"
  },
     




  //Wellness when Working from Home

    
  {
    "type": "html",
    "showQuestionName": "off",
    "showQuestionTitle": "off",
    "html": "<br><br><h3>Wellness when Working from Home </h3><br>\
             <p class=\"full-text hidden\"> With the 'workplace' extending beyond the four walls of the office and enveloping home office setups, workplace wellness programs are beginning to include employees when they work from home as well. Access to counsellors, gyms, yoga, meditation, sports facilities and allied facilities remain as important when at home, as they are when working from office. </p>\
             <p><b>Relatability:</b> Do you relate well to your access (or lack of it) to wellness programs and facilities when working from home? Do you have neighbourhood facilities that you can access? Perhaps you relate better to these facilities than those in the office, because you see your neighbours there? Perhaps not?<br><br>\
            <b>Ease of use:</b> How easy or difficult is it for you to access wellness programs and facilities when you are working from home?</p>\
            <a class=\"read-more-dots\" onclick=\"handleReadMoreClick(this)\">...Show More</a>"
  },

  {
    "type": "rating",
    "name": "q37",
    "title": "1.Wellness when Working from Home | Relatability",          
    "description": "With 10 indicating that it is very easy (for you) to execute a 'Choice of Work Days' and 1 indicating that you find it very difficult to execute a 'Choice of Work Days', please select a number between 1 and 10, indicating your personal relatability score.",
    "rateMax": 5,
    "showQuestionName": "off",
    "showQuestionTitle": "off"
  },
  {
    "type": "rating",
    "name": "q38",
    "title": "2.Wellness when Working from Home | Ease of use",           
    "description": "With 10 indicating that it is very easy (for you) to execute a 'Choice of Work Days' and 1 indicating that you find it very difficult to execute a 'Choice of Work Days', please select a number between 1 and 10, indicating your personal 'ease of use' score.",
    "rateMax": 5,
    "showQuestionName": "off",
    "showQuestionTitle": "off"
  },
  
        ]
      },

 

   ]
}
 

window.handleReadMoreClick = function(element) {
  const parentElement = element.parentElement;
  const fullText = parentElement.querySelector('.full-text');
  const dots = parentElement.querySelector('.read-more-dots');
  
  if (fullText.classList.contains('hidden')) {
    fullText.classList.remove('hidden');
    dots.textContent = '... Show Less';
  } else {
    fullText.classList.add('hidden');
    dots.textContent = '... Show More';
  }
};
