import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import "./loader.css";

const Loader = () => {
  return <div className="loader"></div>;
};

const Callback = () => {
  const [searchParams] = useSearchParams();
  const authCode = searchParams.get("code");
  const [isLoading, setIsLoading] = useState(true);

  const sendAuthCodeToBackend = async (authCode) => {
    const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/api/accessToken`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        xssotoken: authCode,
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to send auth code to backend: ${response.statusText}`);
    }

    const responseData = await response.json();
    return responseData;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("mounted");
        if (!authCode) {
          // Handle case when the user cancels the LinkedIn login
          window.location.href = "/";
          return;
        }

        const responseData = await sendAuthCodeToBackend(authCode);
        console.log("hygh", responseData);
        localStorage.setItem("access_token", responseData.access_token);

        const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/api/profile`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            access_token: localStorage.getItem("access_token"),
          },
        });

        const res = await response.json();
        console.log("userdat", res);
        localStorage.setItem("user", JSON.stringify(res));

        // const docResponse = await fetch(`${process.env.REACT_APP_BASE_API_URL}/api/getDoc`, {
        //   method: "GET",
        //   headers: {
        //     "Content-Type": "application/json",
        //     access_token: localStorage.getItem("access_token"),
        //   },
        // });

        // const docData = await docResponse.json();

        // console.log(docData);

        window.location.pathname = "/survey";
        console.log(window.location.pathname);

        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [authCode]);

  if (isLoading) {
    return <Loader />;
  }

  return null;
};

export default Callback;
